// IMPORTS
import $ from "jquery";

import feather from "feather-icons";

import "overlayscrollbars/css/OverlayScrollbars.css";

import { updateClocks } from "./clocks";
import "./tooltips";
import "./apps";
import "./email";
import "./settings";
import "./notifications";

// CODE
window.onload = function() {
	feather.replace();

	updateClocks();
};
