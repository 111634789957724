import $ from "jquery";

export const elements = {
	// DESKTOP
	desktopDock: $(".desktop--dock"),
	desktopClock: $(".desktop--clock"),
	desktopApps: $(".desktop--app"),

	// APPS
	appCloseBtn: $(".app--close-btn"),

	// CONTACT
	formInputs: $(".app--form-box [name]"),
	formSubmitBtn: $(".app--form-box button"),

	// SETTINGS
	wallpaperOptionBtns: $(".app--option__wallpaper a"),
	themeOptionBtns: $(".app--option__theme a"),
	appearanceOptionBtns: $(".app--option__appearance a")
};
