// IMPORTS
import $ from "jquery";
import feather from "feather-icons";
import { elements } from "./elements";

// CODE
let timeout = null;

export function showNotification(heading, message) {
	clearTimeout(timeout);

	if ($(".notification") && $(".notification").hasClass("show")) {
		$(".notification").remove();
	}

	const notificationHTML = `
		<div class="notification">
			<div class="notification--title-box">
				<h2>${heading}</h2>

				<a href="#"><i data-feather="x"></i></a>
			</div>

			<p>${message}</p>
		</div>		
	`;

	$(".desktop").before(notificationHTML);

	$(".notification a").click(function() {
		$(".notification").removeClass("show");

		window.setTimeout(() => {
			$(".notification").remove();
		}, 400);
	});

	feather.replace();

	timeout = setTimeout(() => {
		$(".notification").addClass("show");

		clearTimeout(timeout);

		timeout = setTimeout(() => {
			$(".notification").removeClass("show");

			clearTimeout(timeout);

			timeout = setTimeout(() => {
				$(".notification").remove();
			}, 400);
		}, 2400);
	}, 20);
}
