// IMPORTS
import $ from "jquery";

import tippy, { sticky } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";

import moment from "moment";

import { changeTheme, changeAppearance } from "./settings";

// CODE
export let tooltips = [];

tooltips = tippy("[data-tippy-content]", {
	offset: [0, 25],
	theme: "modern-light",
	animation: "shift-away",
	duration: 200
});

tooltips.push(
	tippy(".desktop--clock", {
		sticky: true,
		ignoreAttributes: true,
		allowHTML: true,
		appendTo: "parent",
		content: `
		<div class="desktop--popup desktop--popup__clock">
			<h3>${moment().format("h:mm:ss A")}</h3>
			<h4>${moment().format("dddd, MMMM Do")}</h4>
		</div>
	`,

		offset: [0, 20],
		delay: 250,
		maxWidth: "none",
		theme: "modern-light",
		plugins: [sticky],
		animation: "shift-away",
		hideOnClick: false,
		duration: 200,

		onMount() {
			$(".desktop--popup__clock h3").text(moment().format("h:mm:ss A"));
			$(".desktop--popup__clock h4").text(moment().format("dddd, MMMM Do"));

			const time = setInterval(() => {
				$(".desktop--popup__clock h3").text(moment().format("h:mm:ss A"));
				$(".desktop--popup__clock h4").text(moment().format("dddd, MMMM Do"));
			}, 1000);
		}
	})[0]
);

tooltips.push(
	tippy(".desktop--menu-icon[data-tippy-content='Info']", {
		sticky: true,
		interactive: true,
		ignoreAttributes: true,
		plugins: [sticky],
		allowHTML: true,
		content: `
		<div class="desktop--popup desktop--popup__info">
			<h4>Credits & Information</h4>

			<p>Website created and maintained by Ethan Anderson for Ethan Dutson.</p>

			<div class="desktop--credit-row">
				<h5>Clocks:</h5>
				<a href="https://momentjs.com/" target="_blank">moment.js</a>
			</div>

			<div class="desktop--credit-row">
				<h5>Icons:</h5>
				<a href="https://feathericons.com/" target="_blank">
					Feather Icons
				</a>
			</div>
			
			<div class="desktop--credit-row">
				<h5>Font:</h5>
				<a href="https://www.fontfabric.com/fonts/nexa/" target="_blank">
					Nexa
				</a>
			</div>
		</div>
	`,

		offset: [0, 20],
		maxWidth: "none",
		theme: "modern-light",
		animation: "shift-away",
		duration: 200,
		trigger: "click"
	})[0]
);

tooltips.push(
	tippy(".desktop--menu-icon[data-tippy-content='Settings']", {
		sticky: true,
		interactive: true,
		ignoreAttributes: true,
		allowHTML: true,
		content: `
		<div class="desktop--popup desktop--popup__settings">
			<h4>Theme & Appearance</h4>

			<div class="desktop--popup-setting-box">
				<h5>Theme</h5>
				
				<div class="desktop--popup-btn-row">
					<a href="#" data-theme="light">Light</a>
					<a href="#" data-theme="dark">Dark</a>
				</div>
			</div>

			<div class="desktop--popup-setting-box">
				<h5>Appearance</h5>

				<div class="desktop--popup-btn-row">
					<a href="#" data-appearance="default">Default</a>
					<a href="#" data-appearance="modern">Modern</a>
					<a href="#" data-appearance="classic">Classic</a>
				</div>
			</div>
		</div>
	`,

		offset: [0, 20],
		theme: "modern-light",
		plugins: [sticky],
		animation: "shift-away",
		duration: 200,
		trigger: "click",

		onShown() {
			$(".desktop--popup-btn-row [data-theme]").click(function() {
				changeTheme($(this).attr("data-theme"));
			});

			$(".desktop--popup-btn-row [data-appearance]").click(function() {
				changeAppearance($(this).attr("data-appearance"));
			});
		}
	})[0]
);
