// IMPORTS
import { elements } from "./elements";
import moment from "moment";

// CODE
export function updateClocks() {
	elements.desktopClock.text(moment().format("LT"));

	const time = setInterval(() => {
		elements.desktopClock.text(moment().format("LT"));
	}, 1000);
}
