import $ from "jquery";
import { elements } from "./elements";
import OverlayScrollbars from "overlayscrollbars/js/jquery.overlayScrollbars";

let timeout = null;

elements.desktopApps.click(function() {
	clearInterval(timeout);

	$("main").addClass("show");

	$(`[data-app-name="${$(this).attr("data-app-toggle")}"`)
		.show()
		.overlayScrollbars({});

	timeout = setTimeout(() => {
		$(`[data-app-name="${$(this).attr("data-app-toggle")}"`).addClass(
			"animate"
		);
	}, 20);
});

elements.appCloseBtn.click(function() {
	clearInterval(timeout);

	$(this)
		.parents(".app")
		.removeClass("animate");

	timeout = setTimeout(() => {
		$(this)
			.parents(".app")
			.hide();
	}, 200);

	$("main").removeClass("show");
});
