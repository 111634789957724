// IMPORTS
import $ from "jquery";

import images from "./../img/wallpaper-*.png";
import cornerLogos from "./../img/corner-logo__*.svg";
import { elements } from "./elements";
import { tooltips } from "./tooltips";
import { showNotification } from "./notifications";

// CODE
elements.wallpaperOptionBtns.click(function() {
	$(".desktop").css({
		background: `url(${images[$(this).attr("data-wallpaper-select")]})`,
		backgroundSize: "cover"
	});

	showNotification("Wallpaper changed", "Desktop wallpaper updated");
});

export function changeTheme(theme) {
	if (theme === "light") {
		$("body")
			.css("color", "#fff")
			.attr("class", "body__light");
		$(".desktop--app").removeAttr("style");
		$(".desktop--dock").removeAttr("style");

		tooltips.forEach(element => {
			element.setProps({
				theme: "modern-light"
			});
		});

		$(".desktop--popup").removeClass("dark");

		if (!elements.desktopDock.hasClass("desktop--dock__classic")) {
			$(".desktop--corner-logo img").attr("src", cornerLogos.light);
		} else {
			$(".desktop--corner-logo img").attr("src", cornerLogos.dark);
		}

		$(".app").css({ backgroundColor: "#fff", color: "#000" });
		$(".app--close-btn").removeAttr("style");

		$(".desktop--popup").css({ color: "#000" });

		showNotification("Theme changed", "Theme changed to light");
	} else {
		$("body")
			.css("color", "#000")
			.attr("class", "body__dark");
		if (elements.desktopDock.hasClass("desktop--dock__default")) {
			$(".desktop--app").css({ backgroundColor: "#000", color: "#fff" });
		} else {
			$(".desktop--app").css({ backgroundColor: "#fff", color: "#000" });
		}

		tooltips.forEach(element => {
			element.setProps({
				theme: "modern-dark"
			});
		});

		$(".desktop--popup").addClass("dark");

		if (elements.desktopDock.hasClass("desktop--dock__classic")) {
			$(".desktop--corner-logo img").attr("src", cornerLogos.light);
		} else {
			$(".desktop--corner-logo img").attr("src", cornerLogos.dark);
		}

		if (!elements.desktopDock.hasClass("desktop--dock__default")) {
			elements.desktopDock.css({ backgroundColor: "#000", color: "#fff" });
		}

		$(".app").css({ backgroundColor: "#000", color: "#fff" });
		$(".app--close-btn").css({ backgroundColor: "#fff", color: "#000" });

		$(".desktop--popup").css({ color: "#fff" });

		showNotification("Theme changed", "Theme changed to dark");
	}
}

export function changeAppearance(appearance) {
	if (appearance === "default") {
		elements.desktopDock.attr("class", "desktop--dock desktop--dock__default");

		$(".desktop--dock").css({ backgroundColor: "transparent" });

		if ($("body").hasClass("body__light")) {
			$(".desktop--corner-logo img").attr("src", cornerLogos.light);

			$(".desktop--dock").css({ color: "#fff" });

			$(".desktop--app").css({ backgroundColor: "#fff", color: "#000" });
		} else if ($("body").hasClass("body__dark")) {
			$(".desktop--dock").css({ color: "#000" });

			$(".desktop--app").css({ backgroundColor: "#000", color: "#fff" });
		}

		showNotification("Appearance changed", "Appearance changed to default");
	} else if (appearance === "modern") {
		elements.desktopDock.attr("class", "desktop--dock desktop--dock__modern");

		$(".desktop--corner-logo img").attr("src", cornerLogos.light);

		if ($("body").hasClass("body__light")) {
			$(".desktop--dock").css({ backgroundColor: "#fff", color: "#000" });

			$(".desktop--app").css({ backgroundColor: "#000", color: "#fff" });
		} else if ($("body").hasClass("body__dark")) {
			$(".desktop--dock").css({ backgroundColor: "#000", color: "#fff" });

			$(".desktop--app").css({ backgroundColor: "#fff", color: "#000" });

			$(".desktop--corner-logo img").attr("src", cornerLogos.dark);
		}

		showNotification("Appearance changed", "Appearance changed to modern");
	} else {
		elements.desktopDock.attr("class", "desktop--dock desktop--dock__classic");

		$(".desktop--corner-logo img").attr("src", cornerLogos.dark);

		if ($("body").hasClass("body__light")) {
			$(".desktop--dock").css({ backgroundColor: "#fff", color: "#000" });

			$(".desktop--app").css({ backgroundColor: "#000", color: "#fff" });
		} else if ($("body").hasClass("body__dark")) {
			$(".desktop--dock").css({ backgroundColor: "#000", color: "#fff" });

			$(".desktop--app").css({ backgroundColor: "#fff", color: "#000" });

			$(".desktop--corner-logo img").attr("src", cornerLogos.light);
		}

		showNotification("Appearance changed", "Appearance changed to classic");
	}
}

elements.themeOptionBtns.click(function() {
	changeTheme($(this).attr("data-theme"));
});

elements.appearanceOptionBtns.click(function() {
	changeAppearance($(this).attr("data-appearance"));
});
