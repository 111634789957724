// IMPORTS
import $ from "jquery";
import feather from "feather-icons";
import { elements } from "./elements";

// CODE

const SibApiV3Sdk = require("sib-api-v3-sdk");
const defaultClient = SibApiV3Sdk.ApiClient.instance;

const apiKey = defaultClient.authentications["api-key"];
apiKey.apiKey =
	"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";

elements.formSubmitBtn.click(function () {
	if (
		$(elements.formInputs[0]).val() === "" ||
		$(elements.formInputs[1]).val() === "" ||
		$(elements.formInputs[2]).val() === ""
	) {
		showMessageStatus("validate-error");
	} else {
		const values = {
			name: $(elements.formInputs[0]).val(),
			email: $(elements.formInputs[1]).val(),
			subject: $(elements.formInputs[2]).val(),
			message: $(elements.formInputs[3]).val(),
		};

		var apiInstance = new SibApiV3Sdk.TransactionalEmailsApi();

		var sendSmtpEmail = new SibApiV3Sdk.SendSmtpEmail();

		sendSmtpEmail.to = [
			{ name: "Ethan (Ekmand) Dutson", email: "ekmandgaming@gmail.com" },
		];
		sendSmtpEmail.subject = values.subject;
		sendSmtpEmail.sender = {
			name: `"${values.name}" via your website contact form`,
			email: values.email,
		};
		sendSmtpEmail.htmlContent = `
			<p>${values.message}</p>
			<br />
			<p><b>Sent from the <a href="https://ekmand.dev/">ekmand.dev</a> contact form.</b></p>
		`;

		apiInstance.sendTransacEmail(sendSmtpEmail).then(
			function () {
				showMessageStatus("success");
			},
			function (error) {
				showMessageStatus("fail");
				console.error(error);
			}
		);

		// var data = {
		// 	service_id: "elastic_email",
		// 	template_id: "ekmand_contact_submission",
		// 	user_id: "user_sJyPBfW5r7eXtaiPZaqR6",
		// 	template_params: {
		// 		name: $(elements.formInputs[0]).val(),
		// 		email: $(elements.formInputs[1]).val(),
		// 		message: $(elements.formInputs[2]).val(),
		// 	},
		// };

		// $.ajax("https://api.emailjs.com/api/v1.0/email/send", {
		// 	type: "POST",
		// 	data: JSON.stringify(data),
		// 	contentType: "application/json",
		// })
		// 	.done(function () {
		// 		showMessageStatus("success");
		// 	})
		// 	.fail(function (error) {
		// 		showMessageStatus("fail");
		// 		console.error(JSON.stringify(error));
		// 	});
	}
});

function showMessageStatus(status) {
	let timeout = null;

	let icon = "",
		message = "";

	if (status === "success") {
		(icon = "check"), (message = "Message Sent");
	} else if (status === "fail") {
		(icon = "x"), (message = "Message Failed to Send");
	} else if (status === "validate-error") {
		(icon = "alert-circle"),
			(message = "Please make sure all text fields are filled");
	}

	clearTimeout(timeout);
	const messageStatusHTML = `
		<div class="app--message-status app--message-status__${status}">
			<i data-feather="${icon}"></i>
			<h4>${message}</h4>
		</div>
	`;

	elements.formSubmitBtn.before(messageStatusHTML);
	feather.replace();

	timeout = setTimeout(() => {
		$(".app--message-status").addClass("show");

		window.setTimeout(() => {
			$(".app--message-status").removeClass("show");

			window.setTimeout(() => $(".app--message-status").remove(), 200);
		}, 2600);
	}, 20);
}
